import React, { useEffect, useRef, useState } from 'react';
import { first, get, groupBy, sortBy, } from 'lodash';
import {
  CollapsibleV2,
  Col, Row,
  fontFamilies,
  colors,
  Text,
  Link,
  LabeledCheckbox,
  LabeledRadio,
} from '@commonsku/styles';

import { rebuildTooltip } from '../helpers';
import { formatMoney, } from '../../utils';

export default function ProductOptionsByColor(props) {
  const {
    parts,
    selectedSkus,
    skusQty={},
    onToggleSku,
    autoOpen = false,
    message = null,
    multi = false,
  } = props;
  const partsByColor = React.useMemo(() => {
    rebuildTooltip();
    return Object.values(groupBy(parts, 'color'));
  }, [parts]);

  return (<Row>
    {message && <Col xs padded style={{ background: colors.neutrals[20], padding: 16, borderRadius: 5, marginRight: 13, marginBottom: 10, }}>
      <Text style={{ color: colors.neutrals[90], fontFamily: fontFamilies.regular }}>{message}</Text>
    </Col>}
    <Col xs padded>
      {partsByColor.map((v, i) => {
        const selectedPartsLength = v.reduce((acc, p) => acc + (skusQty[p.sku] || (selectedSkus.includes(p.sku) ? 1 : 0) || 0), 0);
        const color = get(first(v), ['color']) || 'Unknown Color';

        return (
          <CollapsibleV2
            key={`productOptions-${i}`}
            style={{ paddingBottom: 10, marginRight: 14, }}
            label={color}
            open={autoOpen && selectedPartsLength > 0}
          >
            <ProductOption
              parts={v}
              selectedSkus={selectedSkus}
              skusQty={skusQty}
              onToggleSku={onToggleSku}
              multi={multi}
              autoOpen={autoOpen && selectedPartsLength > 0}
            />
          </CollapsibleV2>
        );
      })}
    </Col>
  </Row>);
}

function ProductOption(props) {
  const {
    parts=[],
    selectedSkus=[],
    skusQty,
    onToggleSku,
    autoOpen=false,
    multi=false,
  } = props;

  const [opened, setOpened] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (autoOpen && !opened) {
        ref.current?.scrollIntoView({ behavior: "smooth" });
        setOpened(true);
    }
  }, [autoOpen, opened]);

  const onToggleAll = (selectAll) => {
    if (!multi) { return; }
    onToggleSku(
      parts.filter(p => {
        const isSelected = selectedSkus.includes(p.sku);
        return (!isSelected && selectAll === 1) ||
          (isSelected && selectAll === 0);
      }).map(p => p.sku),
      selectAll
    );
  };

  return (
    <Row ref={ref}>
      {multi && <Col xs style={{ marginBottom: 10, marginTop: 10 }}>
        <Link style={{ padding: 10, paddingLeft: 0, }} onClick={() => onToggleAll(1)}>Select all</Link>
        <Link style={{ padding: 10, }} onClick={() => onToggleAll(0)}>Select none</Link>
      </Col>}
      <Col xs padded>
        {parts.map((p, i) => {
          const costs = 'Unknown' !== p.cost ? sortBy(p.costs, 'min_quantity') : [];
          const tooltipHtml = 'Unknown' !== p.cost ? `
            <table style="border: none; color: #fff;">
              <tbody style="border: none; background: ${colors.neutrals['100']};">
                <tr>
                  <th>Quantity</th>
                  ${costs.map(c => `<td>${c.min_quantity}</td>`)}
                </tr>
                <tr>
                  <th>Net Cost</th>
                  ${costs.map(c => `<td>$${formatMoney(c.price)}</td>`)}
                </tr>
              </tbody>
            </table>
          ` : '';
          const isSelected = selectedSkus.includes(p.sku);
          const skuQty = isSelected ? 0 : (multi ? 1 : (first(Object.values(skusQty)) || 1));

          return (
            <Row
              key={`productOption-part-${p.sku}`}
              style={{
                paddingBottom: 10,
                overflowX: 'auto',
                ...(i === 0 ? {paddingTop: 10} : {}),
              }}
            >
              <Col xs sm={2}>
                {multi ? <LabeledCheckbox
                  label={p.size}
                  checked={isSelected}
                  onClick={() => onToggleSku(p.sku, skuQty)}
                  onChange={() => onToggleSku(p.sku, skuQty)}
                /> : <LabeledRadio
                  label={p.size}
                  checked={isSelected}
                  onClick={() => onToggleSku(p.sku, skuQty)}
                  onChange={() => onToggleSku(p.sku, skuQty)}
                />}
              </Col>
              <Col xs sm={2}>{p.sku}</Col>
              <Col xs sm={1.5}>{p.inventory}</Col>
              <Col xs sm={1.5}>
                <span
                  data-html
                  data-for={'Unknown' !== p.cost ? "select_sku_tooltip" : ''}
                  data-tip={tooltipHtml}
                >{p.cost}</span>
              </Col>
              <Col xs sm={5}>{p.description}</Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
}
