import React from 'react';
import { get, padEnd, orderBy, size } from 'lodash';
import {
  Col, Row,
  Csku, Text,
  fontFamilies, colors,
  AlertIcon, LabeledRadio,
} from '@commonsku/styles';
import ProductPriceTable from './ProductPriceTable';
import { getCurrencySymbol, isAlpha } from '../../utils';

const formatCost = (cost) => {
  cost = (''+cost).trim();
  const costSplit = (''+cost).split('.');
  if (costSplit.length === 1) {
    return costSplit[0] + '.00';
  }
  return costSplit[0] + '.' + padEnd(''+costSplit[1], 2, '0').slice(0, 2);
};

const ProductPricesByCategories = React.memo((props) => {
  const {
    costCategories = [],
    selectedSkus = [],
    currency_id = "USD",
    search_query = '',
    onSelectCost,
  } = props;

  const filterSelected = (cc) => (get(cc, ['parts']) || []).map(p => p.sku).find(s => selectedSkus.includes(s)) ? true : false;
  const filterSearchByPart = (p) => {
    if (!search_query) { return true; }
    const query = search_query.toLowerCase();
    return (p.color || '').toLowerCase().includes(query)
          || (p.size || '').toLowerCase().includes(query)
          || (p.sku || '').toLowerCase().includes(query)
          || (p.inventory || '').toLowerCase().includes(query)
          || (p.description || '').toLowerCase().includes(query);
  };
  const filterSearch = (cc) => (get(cc, ['parts']) || []).filter(filterSearchByPart).length > 0;

  const categories = orderBy(
    costCategories.filter(filterSearch),
    [cc => {
      if (cc.key.includes(',')) {
        const labelSplit = cc.key.split(',');
        return labelSplit[0];
      }
      return cc.key;
    }, cc => {
      if (cc.key.includes(',')) {
        const labelSplit = cc.key.split(',');
        return labelSplit[labelSplit.length - 1];
      }
      return cc.key;
    }],
    ['asc', 'asc']
  );

  return (
    <Row>
      {size(costCategories) > 1 && <Col xs padded style={{ background: colors.neutrals[20], padding: 16, borderRadius: 5 }} mb={10}>
        <Text as={'p'} style={{ color: colors.neutrals[90], fontFamily: fontFamilies.regular, marginBottom: 0, }}>
          <AlertIcon color={colors.neutrals[90]} style={{ verticalAlign: 'top', marginRight: 5 }} />
          This product has multiple prices.
        </Text>
      </Col>}
      <Col padded xs sm={3} md={3}>
        {categories.map((cc, i) => {
          const isSelected = filterSelected(cc);
          const onToggle = () => {
            if (isSelected) { return; }
            onSelectCost(cc.parts[0]);
          };

          let currencyCode = '$';
          try {
            currencyCode = getCurrencySymbol(currency_id, currency_id);
          } catch (error) {
            currencyCode = '$';
          }
          if (!currencyCode || isAlpha(currencyCode)) {
            currencyCode = '$';
          }

          let label;
          if (cc.key.includes(',')) {
            const labelSplit = cc.key.split(',');
            label = currencyCode + formatCost(labelSplit[0]) + ' - ' + currencyCode + formatCost(labelSplit[labelSplit.length - 1]);
          } else {
            label = currencyCode + formatCost(cc.key);
          }

          return (
            <Csku
              key={'costCategoryRadio'+i}
              onClick={onToggle}
              style={{
                display: 'block',
                background: isSelected ? colors.white : colors.teal[20],
                borderColor: isSelected ? colors.teal.main : colors.teal[20],
                borderRadius: 20,
                padding: 10,
                paddingLeft: 14,
                paddingTop: 14,
                cursor: 'pointer',
                borderWidth: 3,
                borderStyle: 'solid',
                marginBottom: 10,
              }}>
              <LabeledRadio
                checked={isSelected}
                onChange={onPreventDefault}
                onClick={onPreventDefault}
                label={label}
                labelStyle={{ marginBottom: 0, marginRight: 0, color: colors.teal.main }}
              />
            </Csku>
          );
        })}
      </Col>
      <Col padded xs sm={9} md={9}>
          {costCategories
            .filter(filterSelected)
            .map((cc, i) => (
              <ProductPriceTable
                key={`costCategoryPartsTable-${cc.key}-${i}`}
                parts={(cc.parts || []).filter(filterSearchByPart)}
              />
            ))}
      </Col>
    </Row>
  );
});

function onPreventDefault(e) {
  e && e.stopPropagation();
  e && e.preventDefault();
}

export default ProductPricesByCategories;
