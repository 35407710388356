import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  map, find, sortBy, pickBy, throttle, bindAll,
  isUndefined, isEmpty, isArray,
} from 'lodash';

import Select from './Select';
import Loading from './Loading';

import { formatMoney, sizeSort } from '../utils';
import { axisSort, columnSort, defaultSort, fetchParts, getBasePartsFromSkus, getCostCategoriesByBaseParts, getCostCategory, getOptionsFromSkus, getPartsByBaseParts, reloadInventory } from '../helpers/ps_sku';
import { createFetchProduct } from '../actions/product';
import ProductPricesByCategories from './select-sku/ProductPricesByCategories';
import ProductOptionsByColor from './select-sku/ProductOptionsByColor';

const columnStyle = {
  color: {
    width: '20%'
  },
  size: {
    width: '20%'
  },
  dimension: {
    width: '20%'
  },
  description: {
    width: '20%'
  },
  sku: {
    width: '10%'
  },
  inventory: {
    width: '10%'
  },
  cost: {
    position: 'static',
    width: '20%'
  }
};

const CostMatrixTooltip = ({ base_costs, top }) => {
  const costs = sortBy(base_costs, 'min_quantity');
  return (
    <div className="tooltip cost-tooltip" style={{ top }}>
      <table style={{ width: '100%', margin: 0 }}>
        <tbody>
          <tr>
            <th>Quantity</th>
            {costs.map(c => <td key={c.min_quantity}>{c.min_quantity}</td>)}
          </tr>
          <tr>
            <th>Net Cost</th>
            {costs.map(c => <td key={c.min_quantity}>${formatMoney(c.price)}</td>)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

class ScrollContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.onScrollListener = throttle(this._onScrollListener, 300);
    bindAll(this, ['onScrollListener']);
  }

  componentDidMount() {
    this.scrollTarget.addEventListener('scroll', this.onScrollListener);
  }

  componentWillUnmount() {
    this.scrollTarget.removeEventListener('scroll', this.onScrollListener);
  }

  async _onScrollListener(event) {
    const { scrollThreshold = 0.8, onEndReached = () => {} } = this.props;
    const { loading } = this.state;
    const target = event.target;
    const bottom = target.scrollTop + target.clientHeight;
    if (
      !loading &&
      (
        target.scrollHeight - bottom < target.clientHeight * 3 &&
        (target.scrollHeight - bottom < target.clientHeight || target.scrollHeight * scrollThreshold < bottom)
      )
    ) {
      this.setState({ loading: true });
      await onEndReached();
      this.setState({ loading: false });
    }
  }

  render() {
    const { style = {}, children } = this.props;
    return <div ref={ref => this.scrollTarget = ref } style={{...style, overflowY: 'auto' }}>
      {children}
    </div>;
  }
}

export class SelectSku extends Component {

  constructor(props) {
    super(props);
    this.partRef = {};
    this.state = {
      offset: 0,
      count: 0,
      skus: [],
      filters: {},
      value: props.value || [],
      inventory: {},
      inventoryLoaded: false,
      hoverCost: null
    };

    ['toggleValue', 'onChangePriceFilter', 'getParsedData', 'onMouseEnterCost', 'onMouseLeaveCost'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  componentDidMount() {
    const { product, loadProduct } = this.props;
    if (!product) {
      loadProduct();
    } else {
      this.fetchParts();
      this.reloadInventory();
    }
  }

  componentDidUpdate(prevProps) {
    const { product_id, product, loadProduct } = this.props;
    if (product_id !== prevProps.product_id || product != prevProps.product) {
      if (!product) {
        loadProduct();
      } else {
        this.fetchParts(0);
        this.reloadInventory();
      }
    }
  }

  async fetchParts(offset) {
    const { product, currency_id, priceFilter = false, canToggleValueOnFetch = true } = this.props;
    const state = await fetchParts(product, currency_id, this.state.skus, offset, 3);
    this.setState(state, () => {
      if (!canToggleValueOnFetch) { return; }
      if (priceFilter) {
        this.onChangePriceFilter('cheapest', state.skus);
      } else if (state.skus.length === 1) {
        this.toggleValue(state.skus[0].sku);
      }
    });
  }

  async reloadInventory() {
    const { product } = this.props;
    const state = await reloadInventory(product.ext_product_id ?? null);
    this.setState(state);
  }

  toggleValue(sku) {
    const { multi = false, selectable = true, onSkusSelected = () => {} } = this.props;
    const { skus, value } = this.state;
    if (!selectable) {
      return;
    }
    let newValue = [];
    if (isUndefined(sku)) {
      if (value.length !== skus.length) {
        newValue = map(skus, 'sku');
      }
    } else if (isArray(sku)) {
      if (!multi) {
        return;
      }
      const includes = value.filter(v => sku.includes(v));
      if (includes.length >= sku.length) {
        newValue = value.filter(v => !sku.includes(v));
      } else {
        newValue = value.concat(sku);
      }
    } else {
      newValue = multi
        ? (value.includes(sku) ? value.filter(v => v !== sku) : value.concat(sku))
        : [sku]
      ;
    }
    newValue = skus.filter(sku => newValue.includes(sku.sku)).map(sku => sku.options.reduce(
      (t, o) => ({ ...t, [o.option_axis]: o.option_name }),
      { sku: sku.sku }
    )).sort(
      (a, b) => sizeSort(a.size, b.size)
    ).sort(
      (a, b) => defaultSort(a.color, b.color)
    ).map(
      sku => sku.sku
    );

    this.setState({ value: newValue });
    onSkusSelected(newValue);
  }

  shouldShowLoading() {
    const { product } = this.props;
    const { skus } = this.state;
    return !product || isEmpty(skus);
  }

  onChangePriceFilter(cost_category, skus) {
    const { currency_id, onSkusSelected = () => {}, priceFilter = false } = this.props;
    if (!priceFilter) {
      return;
    }
    if (!skus) {
      skus = this.state.skus;
    }

    const base_skus = skus.map(s => ({
      sku: s.sku,
      cost_category: getCostCategory(s, currency_id)
    }));
    const costCategories = Object.values(base_skus.reduce(
      (c, p) => ({ ...c, [p.cost_category.toString()]: p.cost_category.toString() }),
      {}
    )).sort();

    if (cost_category === 'cheapest') {
      cost_category = costCategories[0];
    }

    const selectedSkus = base_skus.filter(
      s => s.cost_category === cost_category
    ).map(
      s => s.sku
    );
    this.setState(({ filters }) => ({ value: selectedSkus, filters: { ...filters, cost_category } }), () => {
      onSkusSelected(selectedSkus);
    });
  }

  onMouseEnterCost = sku => () => this.setState({ hoverCost: sku });
  onMouseLeaveCost = () => this.setState({ hoverCost: null });

  renderSelectButton() {
    const { multi = false } = this.props;
    return <button className="button select-sku" style={{ marginBottom: 0, float: 'right', width: multi ? '40%' : '50%' }}>{multi ? 'Add' : 'Select'}</button>;
  }

  renderSelectedButton() {
    const { multi = false } = this.props;
    return <button className="button unselect-sku" style={{ marginBottom: 0, float: 'right', width: multi ? '40%' : '50%' }}>{multi ? '✓ Added' : '✓ Selected'}</button>;
  }

  getParsedData() {
    const { currency_id, search_query = '' } = this.props;
    const { skus, filters, inventory, inventoryLoaded } = this.state;

    const options = getOptionsFromSkus(skus);
    const axes = Object.keys(options).sort(columnSort);
    const baseParts = getBasePartsFromSkus(skus, currency_id, inventory, inventoryLoaded);
    const costCategories = getCostCategoriesByBaseParts(baseParts);
    const parts = getPartsByBaseParts(
      baseParts
      .filter(pickBy(filters))
      .filter(p => {
        if(!search_query) { return true; }
        return (p.color || '').toLowerCase().includes(search_query.toLowerCase())
          || (p.size || '').toLowerCase().includes(search_query.toLowerCase())
          || (p.sku || '').toLowerCase().includes(search_query.toLowerCase())
          || (p.inventory || '').toLowerCase().includes(search_query.toLowerCase())
          || (p.description || '').toLowerCase().includes(search_query.toLowerCase());
      }),
      options,
      axes
    );

    const hasDescription = find(skus, 'description');
    const numCols = hasDescription ? axes.length + 4 : axes.length + 3;

    return {
      axes,
      parts,
      numCols,
      costCategories,
      options,
      hasDescription,
    };
  }

  renderTable(data = {}) {
    const { multi, selectAll = false, maxHeight = '24rem', priceFilter = false, } = this.props;
    const { value, skus, hoverCost, filters } = this.state;
    const {
      axes,
      parts,
      numCols,
      costCategories,
      options,
      hasDescription,
    } = data;

    return (
      <table className="outer">
        <thead>
          <tr>
            {axes.map((a, idx) => <th key={a} style={columnStyle[a]}>
              {priceFilter ? (idx === 0 && <label>Select Cost Group:<Select
                options={costCategories}
                change={o => this.onChangePriceFilter(o)}
                value={filters.cost_category}
                withMarginBottom
              /></label>) :
              <Select
                options={[{ key: undefined, value: 'All' }].concat(options[a].sort(axisSort[a]).map(o => ({ key: o, value: o })))}
                change={o => this.setState({ filters: { ...filters, [a]: o } })}
                value={filters[a]}
                withMarginBottom
              />}
            </th>)}
            {hasDescription && <th style={columnStyle.description}>&nbsp;</th>}
            <th style={columnStyle.sku}>&nbsp;</th>
            <th style={columnStyle.inventory}>&nbsp;</th>
	            {!priceFilter && <th style={columnStyle.cost}>
              {(multi && selectAll) ?
                <a className="button" style={{ float: 'right' }} onClick={() => {
                  this.toggleValue();
                }}>{value.length === skus.length ? 'Unselect All' : 'Select All'}</a> :
                <span>&nbsp;</span>
              }
            </th>}
          </tr>
          <tr>
            {axes.map(a => <th key={a} style={{ ...columnStyle[a], textTransform: 'capitalize' }}>{a}</th>)}
            {hasDescription && <th style={columnStyle.description}>Description</th>}
            <th style={columnStyle.sku}>SKU</th>
            <th style={columnStyle.inventory}>Inventory</th>
            {!priceFilter && <th style={columnStyle.cost}>Net Cost</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td ref={ref => this.containerRef = ref} colSpan={numCols} style={{ position: 'relative', padding: 0 }}>
              <div style={{ maxHeight, overflowY: 'auto' }}>
                <table>
                  <tbody style={{ border: 'none' }}>
                    {parts.map(part =>
                      <tr
                        key={part.sku}
                        onClick={() => skus.length > 1 && this.toggleValue(part.sku)}
                      >
                        {axes.map(a => <td key={a} style={columnStyle[a]}>{part[a]}</td>)}
                        {hasDescription && <td style={columnStyle.description}>{part.description}</td>}
                        <td style={columnStyle.sku}>{part.sku}</td>
                        <td style={columnStyle.inventory}>{part.inventory}</td>
                        {!priceFilter && <td
                          ref={ref => this.partRef[part.sku] = ref}
                          className="tooltip-target" style={columnStyle.cost}
                        >
                          <span
                            onMouseEnter={this.onMouseEnterCost(part.sku)}
                            onMouseLeave={this.onMouseLeaveCost}
                            style={{ display: 'inline-block', marginTop: '0.5rem' }}
                          >
                            {part.cost}
                          </span>
                          {('Unknown' !== part.cost && hoverCost === part.sku) &&
                          <CostMatrixTooltip base_costs={part.costs}
                            top={(this.partRef[part.sku].getBoundingClientRect().bottom - this.containerRef.getBoundingClientRect().top) + 'px'}
                          />}
                          {!!this.props.onSkusSelected && (value.includes(part.sku) ? this.renderSelectedButton() : this.renderSelectButton())}
                        </td>}
                      </tr>
                    )}
                    {0 === parts.length && <tr>
                      <td colSpan={axes.length + 2 + (priceFilter ? 0 : 1)}>No available parts</td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderPricesByColor(data) {
    const costCategories = data.costCategories;
    return <ProductPricesByCategories
      costCategories={costCategories}
      selectedSkus={this.state.value}
      currency_id={this.props.currency_id}
      search_query={this.props.search_query}
      onSelectCost={(op) => {
        this.onChangePriceFilter(op.cost_category);
      }}
    />;
  }

  render() {
    if (this.shouldShowLoading()) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loading />
        </div>
      );
    }
    const data = this.getParsedData();
    if (this.props.priceFilter) {
      return this.renderPricesByColor(data);
    }

    if (this.props.view_type === 'OptionsByColor') {
      return <ProductOptionsByColor
        axes={data.axes}
        parts={data.parts}
        skus={this.state.skus}
        selectedSkus={!!this.props.onSkusSelected ? this.state.value : []}
        skusQty={{}}
        onToggleSku={(sku, qty = 1) => {
          if (!this.props.onSkusSelected) { return; }
          this.toggleValue(sku);
        }}
        autoOpen={!this.props.multi}
        message={this.props.message}
        multi={this.props.multi}
      />;
    }
    return this.renderTable(data);
  }
}

export const mapStateToProps = (state, ownProps) => ({
  product: ownProps.product || state.entities.products[ownProps.product_id]
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  loadProduct: () => dispatch(createFetchProduct(ownProps.product_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSku);
