import React from 'react';
import { Csku, colors, } from '@commonsku/styles';

const ProductPriceTable = React.memo((props) => {
  const {
    parts,
  } = props;
  const color = colors.neutrals[30];
  const trHoverStyle = {
    background: `${color} !important`,
    cursor: 'auto !important',
  };
  return (
    <div>
      <Csku as='table' style={{
        borderCollapse: 'separate !important',
        border: `solid ${color} 1px`,
        borderRadius: 6,
      }}>
        <Csku as='thead' style={{ border: 'none', background: color }}>
          <Csku as='tr'>
            <Csku as='th' style={{ padding: 18 }}>Color</Csku>
            <Csku as='th' style={{ padding: 18 }}>Size</Csku>
            <Csku as='th' style={{ padding: 18 }}>SKU</Csku>
            <Csku as='th' style={{ padding: 18 }}>Inventory</Csku>
            <Csku as='th' style={{ padding: 18 }}>Net Cost</Csku>
          </Csku>
        </Csku>
        <Csku as='tbody' style={{ border: 'none', background: color }}>
          {parts.map((p, i) => (
            <Csku as='tr'
              key={`costCategoryPart-${p.sku}-${i}`}
              style={{
                ":hover": { 'td': trHoverStyle },
              }}
            >
              <Csku as='td' style={{ padding: 18 }}>{p.color}</Csku>
              <Csku as='td' style={{ padding: 18 }}>{p.size}</Csku>
              <Csku as='td' style={{ padding: 18 }}>{p.sku}</Csku>
              <Csku as='td' style={{ padding: 18 }}>{p.inventory}</Csku>
              <Csku as='td' style={{ padding: 18 }}>{p.cost}</Csku>
            </Csku>
          ))}
        </Csku>
      </Csku>
    </div>
  );
});

export default ProductPriceTable;
